<template>
  <layout-full>
    <router-view />
  </layout-full>
</template>

<script>
import LayoutFull from '@core/layouts/layout-full/LayoutFull.vue'
// eslint-disable-next-line import/extensions

export default {
  components: {
    LayoutFull,
    // eslint-disable-next-line vue/no-unused-components
  },
}
</script>
